import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllObservations } from "../../data/reducers/observation.reducer";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import { Modal, Button } from "react-bootstrap";

import DataTable from "react-data-table-component";
import ReactExport from "react-data-export";
import axios from "axios";
import fileDownload from "js-file-download";
import { get, getAuthHeaders } from "../../data/services/services.common";
import { api } from "../../data/services/services.common";
import { constants } from "../../config";
import {
  getAllExcelData,
  getExcelDataByManager,
} from "../../data/reducers/excel.reducer";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelDashboard = () => {
  const role = localStorage.getItem(constants.KEY_USER_ROLE);
  const history = useHistory();
  const dispatch = useDispatch();

  const observationsList = useSelector(
    (state) => state.observationReducer
  ).observations;

  useEffect(() => {
    dispatch(getAllObservations());
  }, []);

  const [startDate, setStartDate] = useState("01/05/2021");
  const [endDate, setEndDate] = useState("01/05/2021");
  const [from, setFrom] = useState("2021/05/01");
  const [to, setTo] = useState("2021/05/01");
  const [exportData, setExportData] = useState("");
  const [show, setShow] = useState(false);
  const [blob, setBlob] = useState("");
  const [showTable, setShowTable] = useState(false);

  const [observationID, setObservationID] = useState(0);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    setExportData(
      observationsList.filter((observation) => {
        const listDate = new Date(observation.date);
        const from = new Date(startDate);
        const to = new Date(endDate);
        return listDate >= from && listDate <= to;
      })
    );
  }, [startDate, endDate]);

  const downloadExcel = () => {
    const getExcelSheet = async () => {
      try {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = "ExportExcel.xlsx";
        const params = {
          startDate: from,
          endDate: to,
        };
        const responseType = "blob";
        const res =
          role !== "Manager"
            ? await dispatch(getAllExcelData({ params, responseType }))
            : await dispatch(getExcelDataByManager({ params, responseType }));
        link.href = URL.createObjectURL(
          new Blob([res.payload], { type: ".xlsx" })
        );
        link.click();
      } catch (err) {
        console.log(err.message);
      }
    };

    getExcelSheet();
  };

  const handleDatePicker = (e) => {
    const dates = e.target.value.split(" - ");
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    const yyyy1 = dates[0].split("/")[2];
    const mm1 = dates[0].split("/")[0];
    const dd1 = dates[0].split("/")[1];
    setFrom(`${yyyy1}/${mm1}/${dd1}`);
    const yyyy = dates[1].split("/")[2];
    const mm = dates[1].split("/")[0];
    const dd = dates[1].split("/")[1];
    setTo(`${yyyy}/${mm}/${dd}`);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.isMoreActionRequired == true,
      style: {
        backgroundColor: "#e74c3c",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const tableColumns = [
    { name: "ID", selector: "id" },
    {
      name: "Date",
      grow: 2,
      selector: "date",
      sortable: true,
      cell: (row) => new Date(row.date).toLocaleDateString(),
    },
    { name: "Client Name", grow: 2, selector: "client", sortable: true },
    { name: "Site Name", grow: 2, selector: "siteName", sortable: true },
    { name: "Contractor", grow: 2, selector: "contractor", sortable: true },
    { name: "Category", grow: 2, selector: "category", sortable: true },
    {
      name: "Images",
      selector: "siteManagementImages.length",
      sortable: true,
      cell: (row) => (
        <>
          <i class="far fa-image"></i>&nbsp;{row.siteManagementImages.length}
        </>
      ),
    },
    { name: "Reported By", grow: 2, selector: "reportedBy", sortable: true },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button
            variant="outline"
            onClick={(e) => {
              setObservationID(row.id);
              handleShow();
            }}
          >
            <i class="fas fa-eye"></i>
          </Button>
          {/*   <Link to={'/observations/view?observationId=' + row.id} className='btn btn-outline btn-sm m-1'><i className='fas fa-eye' ></i></Link>  */}
        </div>
      ),
    },
  ];

  const observationData = (
    <div className="card" style={{ marginTop: "1rem" }}>
      <div className="card-body">
        <div className="row">
          <div className="col-md-4">
            <h4>Filtered List</h4>
          </div>
          <div className="offset-md-4 col-md-4 text-right d-flex">
            <DateRangePicker
              onApply={handleDatePicker}
              initialSettings={{ startDate: startDate, endDate: endDate }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Enter Date Range"
              />
            </DateRangePicker>
            {exportData.length > 0 && (
              <Button
                onClick={() => {
                  downloadExcel();
                }}
              >
                Download
              </Button>
            )}
          </div>
          <div className="col-md-12">
            <DataTable
              id="tableToExcel"
              noHeader={true}
              columns={tableColumns}
              data={exportData}
              conditionalRowStyles={conditionalRowStyles}
              pagination={true}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Excel</li>
      </ol>
      <div className="row">
        <div className="col-md-12">
          <h4>Excel Download</h4>
          {observationsList !== undefined ? (
            observationData
          ) : (
            <div>No observations available</div>
          )}
        </div>
      </div>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>View Observation Details</h2>
            <small>See all details of an observation by site manager</small>
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          <ViewObservation
            observationId={observationID}
            closePopUp={handleClose}
          />
        </Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
    </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExcelDashboard;
