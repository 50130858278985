import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllExcelDataAPI,
  getExcelDataByManagerAPI,
} from "../services/excel.service";

export const getAllExcelData = createAsyncThunk(
  "excel/getAllExcelData",
  async (payload, thunkAPI) => {
    const response = await getAllExcelDataAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getExcelDataByManager = createAsyncThunk(
  "excel/getExcelDataByManager",
  async (payload, thunkAPI) => {
    const response = await getExcelDataByManagerAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const excelSlice = createSlice({
  name: "excel",
  initialState: {
    excels: [],
  },
  extraReducers: {
    [getAllExcelData.fulfilled]: (state, action) => {
      state.excels = action.payload;
    },
    [getExcelDataByManager.fulfilled]: (state, action) => {
      state.excels = action.payload;
    },
  },
});

export default excelSlice.reducer;
