import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

// redux dependencies
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducer from "./data/reducers";
import thunk from "redux-thunk";

import * as Chart from 'chart.js';

// initialize the redux store
const reduxStore = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


//Fix chartjs printing:
window.onbeforeprint = (ev) => {
  for (var id in Chart.instances) {
    let instance = Chart.instances[id];
    let b64 = instance.toBase64Image();
    let i = new Image();
    //Instead of the below, you could save something on the
    //chart that decides what to change this to. This worked for me
    //however:
    i.style.maxWidth = "100%";
    i.src = b64;
    let parent = instance.canvas.parentNode;
    parent.style.height = "auto";
    instance.tempImage = i;
    instance.oldDisplay = instance.canvas.style.display;
    instance.canvas.style.display = "none";
    parent.appendChild(i);
  }
};

window.onafterprint = (ev) => {
  for (var id in Chart.instances) {
    let instance = Chart.instances[id];
    if (instance.tempImage) {
      let parent = instance.canvas.parentNode;
      parent.removeChild(instance.tempImage);
      instance.canvas.style.display = instance.oldDisplay;
      delete instance.oldDisplay;
      delete instance.tempImage;
    }
  }
};