import { getWithParams, getAuthHeaders, api } from "./services.common";

export const getAllExcelDataAPI = async ({ params, responseType }) => {
  let url = `${api}/sitemanagement/downloaddashboardreport`;
  let authHeader = getAuthHeaders();
  return await getWithParams(url, authHeader, params, responseType);
};

export const getExcelDataByManagerAPI = async ({ params, responseType }) => {
  let url = `${api}/sitemanagement/downloaddashboardreportbysiteManager`;
  let authHeader = getAuthHeaders();
  return await getWithParams(url, authHeader, params, responseType);
};
