import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { getSiteById, updateSite, activatedeactivatedeleteSite } from "../../data/reducers/site.reducer";
import { errorMessage, successMessage } from "../../data/reducers/alert.reducer";

import swal from "sweetalert";

const EditSite = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const site = useSelector((state) => state.siteReducer)?.selectedSite;

    const siteId = queryString.parse(location.search).siteId;

    const [siteLocal, setSite] = useState({
        id: site.id,
        siteName: site.siteName,
        manHours: site.manHours,
    });

    useEffect(() => {
        dispatch(getSiteById(siteId));
    }, [siteId]);

    useEffect(() => {
        if (site !== undefined) {
            setSite(site);
        }
    }, [site]);

    const update = async () => {
        try {
            if (siteLocal.manHours <= 0 || isNaN(siteLocal.manHours)) {
                dispatch(errorMessage("Invalid value of  Worked Hours"));
            } else {
                let response = await dispatch(
                    updateSite({ id: siteId, siteName: siteLocal.siteName, manHours: siteLocal.manHours })
                );
                if (response.error) {
                    dispatch(errorMessage(response.error.message));
                } else {
                    dispatch(successMessage(`Site has been updated!`));
                    history.push("/sites");
                }
            }
        } catch (e) {
            dispatch(errorMessage(e));
        }
    };

    const activatedeactivatedelete = async (action) => {
        try {
            swal({
                title: `${action}`,
                text: `Are you sure to ${action.toLowerCase()} this site?`,
                icon: "warning",
                buttons: ["Cancel", "Yes"],
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    let response = await dispatch(activatedeactivatedeleteSite({ siteId, action }));
                    if (response.error) {
                        dispatch(errorMessage(response.error.message));
                    } else {
                        dispatch(successMessage(`Site has been ${action.toLowerCase()}d!`));
                        history.push("/sites");
                    }
                }
            });
        } catch (e) {
            dispatch(errorMessage(e));
        }
    };

    return (
        <>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/sites">Sites</Link>
                </li>
                <li className="breadcrumb-item active">View Site</li>
            </ol>
            <div className="row">
                <div className="col-md-12">
                    <h2>View Site Details</h2>
                    <div>See and modify details of a site</div>
                    <br />
                    <div className="card">
                        <div className="card-body">
                            <form className="row form">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Site Name</label>
                                        <input
                                            className="form-control"
                                            value={siteLocal?.siteName}
                                            placeholder="Site Name"
                                            onChange={(e) => {
                                                setSite({ ...siteLocal, siteName: e.target.value });
                                            }}
                                        ></input>
                                    </div>
                                    <div className="form-group">
                                        <label>Worked Hours</label>
                                        <input
                                            className="form-control"
                                            value={siteLocal?.manHours}
                                            placeholder="Worked Hours"
                                            onChange={(e) => {
                                                setSite({ ...siteLocal, manHours: e.target.value });
                                            }}
                                        ></input>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <button
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            update();
                                        }}
                                    >
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 my-3">
                    <button
                        className="btn btn-secondary mr-2"
                        onClick={(e) => {
                            history.push("/sites");
                        }}
                    >
                        Go Back
                    </button>
                    <button
                        className="btn btn-danger mr-2"
                        onClick={(e) => {
                            e.preventDefault();
                            activatedeactivatedelete("DELETE");
                        }}
                    >
                        Delete Site
                    </button>
                    <button
                        className={`btn ${siteLocal?.isActive ? "btn-secondary" : "btn-success"}`}
                        onClick={(e) => {
                            e.preventDefault();
                            activatedeactivatedelete(siteLocal?.isActive ? "DEACTIVATE" : "ACTIVATE");
                        }}
                    >
                        {siteLocal?.isActive ? "Deactivate Site" : "Activate Site"}
                    </button>
                </div>
            </div>
        </>
    );
};

export default EditSite;
