import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getAllUsersAPI,
    addAdminUserAPI,
    addUserAPI,
    addManagerUserAPI,
    updateUserSiteAssociationAPI,
    getUserSiteLinkByUserIdAPI,
    resetPasswordAPI,
    resetPasswordWithTokenAPI,
    forgotPasswordAPI,
    updateRoleAPI,
    deactivateUserAPI,
    reactivateUserAPI,
    getUserByUsernameAPI,
} from "../services/user-management.service";

export const getAllUsers = createAsyncThunk(
    "userManagement/getAllUsers",
    async (payload, thunkAPI) => {
        const response = await getAllUsersAPI();
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const getUserByUsername = createAsyncThunk(
    "userManagement/getUserByUsername",
    async (payload, thunkAPI) => {
        const response = await getUserByUsernameAPI(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const getUserSiteLinkByUserId = createAsyncThunk(
    "userManagement/getUserSiteLinkByUserId",
    async (payload, thunkAPI) => {
        const response = await getUserSiteLinkByUserIdAPI(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const createAdminUser = createAsyncThunk(
    "userManagement/createAdminUser",
    async (payload, thunkAPI) => {
        const response = await addAdminUserAPI(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const createUser = createAsyncThunk(
    "userManagement/createUser",
    async (payload, thunkAPI) => {
        const response = await addUserAPI(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const createManagerUser = createAsyncThunk(
    "userManagement/createManagerUser",
    async (payload, thunkAPI) => {
        const response = await addManagerUserAPI(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const resetPassword = createAsyncThunk(
    "userManagement/resetPassword",
    async (payload, thunkAPI) => {
        const response = await resetPasswordAPI(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const updateRole = createAsyncThunk(
    "userManagement/updateRole",
    async (payload, thunkAPI) => {
        const response = await updateRoleAPI(
            payload.userName,
            payload.roleToUpdate
        );
        if (response.isSuccessful === true) {
            return payload;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const updateUserSiteAssociation = createAsyncThunk(
    "userManagement/updateUserSiteAssociation",
    async (payload, thunkAPI) => {
        const response = await updateUserSiteAssociationAPI(payload);
        if (response.isSuccessful === true) {
            return payload;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const toggleUserActivation = createAsyncThunk(
    "userManagement/toggleUserActivation",
    async (payload, thunkAPI) => {
        var response = undefined;
        if (payload.isActive === true) {
            response = await deactivateUserAPI(payload.userName);
        } else {
            response = await reactivateUserAPI(payload.userName);
        }

        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const resetPasswordWithToken = createAsyncThunk(
    "userManagement/resetPasswordWithToken",
    async (payload, thunkAPI) => {
        const response = await resetPasswordWithTokenAPI(
            payload.credentials,
            payload.token
        );
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const forgotPassword = createAsyncThunk(
    "userManagement/forgotPassword",
    async (payload, thunkAPI) => {
        const response = await forgotPasswordAPI(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

const userManagementSlice = createSlice({
    name: "userManagement",
    initialState: {
        users: [],
        selectedUser: {},
        sitesLinked: [],
    },
    reducers: {
        addSite: (state, action) => {
            if (state.sitesLinked == null) {
                state.sitesLinked = [];
            }
            state.sitesLinked.push(action.payload);
        },
        removeSite: (state, action) => {
            state.sitesLinked = state.sitesLinked.filter(
                (site) => site !== action.payload
            );
        },
    },
    extraReducers: {
        [getAllUsers.fulfilled]: (state, action) => {
            state.users = action.payload;
        },
        [getUserByUsername.fulfilled]: (state, action) => {
            state.selectedUser = action.payload;
        },
        [toggleUserActivation.fulfilled]: (state, action) => {
            state.selectedUser.isActive =
                state.selectedUser.isActive === true ? false : true;
        },
        [updateRole.fulfilled]: (state, action) => {
            state.selectedUser.role =
                action.payload.isAdmin === true ? "Admin" : "General-User";
        },
        [getUserSiteLinkByUserId.fulfilled]: (state, action) => {
            state.sitesLinked = action.payload.siteIds;
        },
        [updateUserSiteAssociation.fulfilled]: (state, action) => {
            state.sitesLinked = action.payload.siteIds;
        },
        // [createUser.fulfilled]: (state, action) => {
        //     state.users.push(action.payload);
        // },
        // [createAdminUser.fulfilled]: (state, action) => {
        //     state.users.push(action.payload);
        // },
    },
});

export const { addSite, removeSite } = userManagementSlice.actions;

export default userManagementSlice.reducer;
