import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../data/reducers/auth.reducer";
import {
    getUserByUsername,
    resetPassword,
    toggleUserActivation,
    updateRole,
    addSite,
    removeSite,
    updateUserSiteAssociation,
    getUserSiteLinkByUserId,
    getAllUsers,
} from "../../data/reducers/user-management.reducer";
import {
    errorMessage,
    successMessage,
} from "../../data/reducers/alert.reducer";
import { getAllDropdowns } from "../../data/reducers/configuration-data.reducer";

const EditUser = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const username = queryString.parse(location.search).username;

    const logoutOnCompletion = queryString.parse(location.search).logout;

    const { sites } = useSelector((state) => state.configurationReducer);

    let { email, role, isActive, id } = useSelector(
        (state) => state.userManagementReducer
    ).selectedUser;

    const [userRole, setUserRole] = useState("");

    setTimeout(() => {
        setUserRole(role);
    }, 100);

    const { sitesLinked } = useSelector((state) => state.userManagementReducer);

    const [userCredentials, setUserCredentials] = useState({ email });

    const fetchUser = async () => {
        try {
            let response = await dispatch(getUserByUsername(username));
            if (response.error) {
                dispatch(errorMessage(response.error.message));
            }
            // dispatch(getAllDropdowns())

            setUserCredentials({
                ...userCredentials,
                email: response.payload.email,
            });
        } catch (e) {
            console.log(e);
            dispatch(errorMessage(e));
        }
    };

    useEffect(() => {
        fetchUser();
        dispatch(getAllDropdowns());
        if (id) {
            dispatch(getUserSiteLinkByUserId(id));
        }
    }, [username, id, role, userRole]);

    const reset = async () => {
        if (userCredentials.password !== userCredentials.confirmPassword) {
            dispatch(
                errorMessage("Password and Confirm Password do not match.")
            );
            return;
        }
        var regEx = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!.%*?&])[A-Za-z\\d@$!.%*?&]{8,}$"
        );
        if (!regEx.test(userCredentials.password)) {
            dispatch(
                errorMessage(
                    "Password must contain at least one special character, one number, one lower case and one upper case character and should be of 8 characters."
                )
            );
            return;
        }
        try {
            let response = await dispatch(resetPassword(userCredentials));
            if (response.error) {
                console.log(response.error);
                dispatch(errorMessage(response.error.message));
            } else {
                if (logoutOnCompletion) {
                    dispatch(logoutUser());
                } else {
                    history.push("/users");
                    dispatch(getAllUsers());
                }
            }
        } catch (e) {
            console.log(e);
            dispatch(errorMessage(e));
        }
    };

    const toggleActivation = () => {
        try {
            let response = dispatch(
                toggleUserActivation({ userName: username, isActive })
            );
            if (response.error) {
                console.log(response.error);
                dispatch(errorMessage(response.error.message));
            } else {
                dispatch(
                    successMessage(
                        `User ${
                            isActive === true ? "Deactivated" : "Activated"
                        } successfully!`
                    )
                );
            }
        } catch (e) {
            console.log(e);
            dispatch(errorMessage(e));
        }
    };

    const changeRole = (userRoleID) => {
        try {
            let response = dispatch(
                updateRole({
                    userName: username,
                    roleToUpdate: userRoleID,
                })
            );
            if (response.error) {
                console.log(response.error);
                dispatch(errorMessage(response.error.message));
            } else {
                history.push("/users");
                dispatch(getAllUsers());
                dispatch(successMessage("User role updated successfully!"));
            }
        } catch (e) {
            console.log(e);
            dispatch(errorMessage(e));
        }
    };

    const updateUserSites = async () => {
        try {
            let response = await dispatch(
                updateUserSiteAssociation({ UserId: id, siteIds: sitesLinked })
            );

            if (response.error) {
                dispatch(errorMessage(response.error.message));
            } else {
                history.push("/users");
                dispatch(getAllDropdowns());
            }
        } catch (e) {
            console.log(e);
            dispatch(errorMessage(e));
        }
    };

    const showSites = () => {
        return sites?.map((site) => (
            <Form.Check
                key={site.id}
                label={site.siteName}
                checked={sitesLinked?.includes(site.id) ? true : false}
                onChange={(e) => {
                    e.currentTarget.checked
                        ? dispatch(addSite(site.id))
                        : dispatch(removeSite(site.id));
                }}
            ></Form.Check>
        ));
    };

    return (
        <>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/users">Users</Link>
                </li>
                <li className="breadcrumb-item active">Edit User</li>
            </ol>
            <div className="row">
                <div className="col-md-12">
                    <h2>Edit User</h2>
                    <div>Reset user's password here.</div>
                    <br />
                    <div className="card">
                        <div className="card-body">
                            <form className="row form">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input
                                            className="form-control"
                                            value={username}
                                            placeholder="User Name"
                                            disabled
                                        ></input>
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            className="form-control"
                                            value={email}
                                            placeholder="Email"
                                            disabled
                                        ></input>
                                    </div>
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            onChange={(e) => {
                                                setUserCredentials({
                                                    ...userCredentials,
                                                    password: e.target.value,
                                                });
                                            }}
                                        ></input>
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            onChange={(e) => {
                                                setUserCredentials({
                                                    ...userCredentials,
                                                    confirmPassword:
                                                        e.target.value,
                                                });
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            console.log(
                                                "resetting password..."
                                            );
                                            reset();
                                        }}
                                    >
                                        Change Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {role !== "Admin" && (
                        <div className="card mt-4">
                            <div className="card-body">
                                <h4>Site Association</h4>
                                <p>
                                    Select sites which are associated with this
                                    user.
                                </p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <ul role="listbox">{showSites()}</ul>
                                    </div>
                                </div>
                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        updateUserSites();
                                    }}
                                >
                                    Update Associated Sites
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="col-md-6 my-3">
                    <button
                        className="btn btn-secondary"
                        onClick={(e) => {
                            e.preventDefault();
                            history.push("/users");
                        }}
                    >
                        Go Back
                    </button>
                </div>

                <div className="col-md-6 my-3 text-right">
                    {userRole === "Admin" ? (
                        <>
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    changeRole(2);
                                }}
                            >
                                Make Manager
                            </button>
                            &nbsp;
                        </>
                    ) : null}
                    {userRole === "Admin" ? (
                        <>
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    changeRole(3);
                                }}
                            >
                                Make General-User
                            </button>
                        </>
                    ) : null}
                    {userRole === "Manager" ? (
                        <>
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    changeRole(1);
                                }}
                            >
                                Make Admin
                            </button>
                            &nbsp;
                        </>
                    ) : null}
                    {userRole === "Manager" ? (
                        <>
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    changeRole(3);
                                }}
                            >
                                Make General-User
                            </button>
                        </>
                    ) : null}
                    {userRole === "General-User" ? (
                        <>
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    changeRole(1);
                                }}
                            >
                                Make Admin
                            </button>
                            &nbsp;
                        </>
                    ) : null}
                    {userRole === "General-User" ? (
                        <>
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    changeRole(2);
                                }}
                            >
                                Make Manager
                            </button>
                        </>
                    ) : null}
                    &nbsp;
                    <button
                        className="btn btn-danger"
                        onClick={(e) => {
                            toggleActivation();
                        }}
                    >
                        {isActive === true ? "Deactivate" : "Activate"} User
                    </button>
                </div>
            </div>
        </>
    );
};

export default EditUser;
